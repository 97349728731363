import { defineStore } from "pinia";
import { useNuxtApp, showError } from "nuxt/app";
import { $useFetch } from "~/composables/useFetch";

export const useQualitiesStore = defineStore("qualities", {
	state: () => ({
		qualities: [],
	}),
	actions: {
		async fetchSectionTree() {
			const { api } = useNuxtApp();

			const { error } = await $useFetch(api.qualities, {
				method: "GET",
				onResponse({ response }) {
					if (response?.status === 200) useQualitiesStore().qualities = response._data.data;
				},
			});

			if (error.value) {
				throw showError({ statusCode: 500, statusMessage: error.value.message });
			}
		},

		getQualitieById(id) {
			return useQualitiesStore().qualities.find((e) => (e.id === id ? e : null));
		},
	},
	getters: {
		getQualities() {
			return this.qualities;
		},
	},
});
